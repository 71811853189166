<template>
  <div v-if="Object.keys(item.unity).length != 0">
    <div class="header-files">
      <img
        data-anima="top"
        :src="item.unity.enterprise ? item.unity.enterprise.image : null"
        alt=""
      />
      <p>
        UNIDADE {{ item.unity.number }} | {{ item.unity.tipology?.name }} |
        {{ item.unity.enterprise.area }}
      </p>

    
    </div>
    <div data-anima="bottom" v-if="item.unity.tipology">
      <div class="files-itens">
        <div
          class="container-item-file"
          v-if="item.unity.tipology.files.length"
        >
          <div
            class="item-file"
            v-for="(file, index) in item.unity.tipology.files"
            :key="file.id"
          >
            <span>{{ file.description || `ARQUIVO ${index}` }}</span>
            <span class="file-name">{{ file.name }}</span>
            <a
              :id="'download'+file.id"
              class="action-file"
              @click.prevent="downloadFile(file.id, file.path_name)"
            >
            <!-- @click.prevent="downloadFile(file.path_name)" -->
              <div>
                <span>BAIXAR</span>
                <!-- <p>3.3MB</p> -->
              </div>
              <div >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-download"
                  style="opacity: 0.5"
                >
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline points="7 10 12 15 17 10"></polyline>
                  <line x1="12" y1="15" x2="12" y2="3"></line>
                </svg>
              </div>
            </a>
            <div style="display:none" class="action-file" :id="'downloadmsg'+file.id">
             
              <b>BAIXANDO <img width="31px" src="https://r4marketing.com.br/assets/images/default/ajax-loader.gif" /></b>
            
            </div>
          </div>
        </div>
        <div
          class="container-item-file"
          v-if="!item.unity.tipology.files.length"
        >
          <div class="item-file error">
            <h5>
              <img src="@/assets/img/icon/notify_info.svg" alt="" />
              Não há arquivos cadastrados para esse item
            </h5>
          </div>
        </div>
        <div class="tipology-img" v-if="item.unity.tipology">
          <div>
            <img
              :src="item.unity.tipology.image"
              :alt="item.unity.tipology.name"
            />
            <span>{{ item.unity.tipology.description }}</span>
          </div>
        </div>
      </div>
      <!-- <a class="action-file download-all-file" @click.prevent="baixarTodos">
        <div>
          <span>BAIXAR TODOS</span>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-download"
            style="opacity: 0.5"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
        </div>
      </a> -->
      <div v-if="item.unity.tipology.files.length >1">
         <div v-if="loading_all_download == false" class="mb-3 mr-5 float-right mt-3" style="cursor:pointer" @click="downloadFiles()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-download"
                  style="opacity: 0.5"
                >
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline points="7 10 12 15 17 10"></polyline>
                  <line x1="12" y1="15" x2="12" y2="3"></line>
                </svg>
                Baixar todos os arquivos
          </div> 
          <div v-else>
            <div class="mb-3 mr-5 float-right mt-3">Aguarde, baixando arquivos <img width="31px" class="" src="https://r4marketing.com.br/assets/images/default/ajax-loader.gif" /></div>
          </div>
      </div>
    </div>
    <div v-else>
      <div class="container-item-file">
        <div class="item-file error p-4">
          <h5>
            <img src="@/assets/img/icon/notify_info.svg" alt="" />
            Não há tipologia cadastrada para neste item
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TipologyFileDownloadService from "@/services/resources/TipologyFileDownloadService";
const serviceTipologyFileDownloadService = TipologyFileDownloadService.build();

export default {
  name: "TipologyFile",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
     loading_all_download: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
  },
  methods: {
    downloadFile(url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.download = true;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadFiles() {
      this.loading_all_download = true
      serviceTipologyFileDownloadService.download("/download-tipology-file", "", this.user.id, "arquivos.zip").then(function(re) {
        this.loading_all_download = false
          console.log(re);
      }.bind(this))
    },
    downloadFile(id, path) {
      let information = {
        file_id: id
      }
      document.getElementById("download"+id).style.display="none"
      document.getElementById("downloadmsg"+id).style.display="flex"

      serviceTipologyFileDownloadService.download("/download-tipology-file", information, this.user.id, path).then(function(re) {
          document.getElementById("download"+id).style.display="flex"
         document.getElementById("downloadmsg"+id).style.display="none"
          console.log(re);
      }.bind(this))
    },
    baixarTodos() {
      this.item.files.forEach((file, index) => {
        setTimeout(() => {
          this.downloadFile(file.path_name, index);
        }, 1000);
      });
    },
  },
};
</script>

<style scoped>
.header-files * {
  font-weight: 600;
  color: #797979;
}
.header-files img {
  max-width: 150px;
  margin-bottom: 20px;
}
.header-files p {
  text-transform: uppercase;
  margin-bottom: 10px !important;
}
.files-itens {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.container-item-file {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item-file {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f4f4f6;
  padding: 0 20px;
  flex: 1;
}
.item-file.error {
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-file h5 {
  padding: 15px;
  border-radius: 5px;
  background-color: #f5f9ff;
  color: #3483fa;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 15px;
}
.item-file:first-child {
  border-top-left-radius: 16px;
}
.item-file:last-child {
  border-bottom-left-radius: 16px;
}

.item-file p {
  margin: 0;
}
.item-file span {
  color: #797979;
  font-weight: 600;
  font-size: 13px;
}
.item-file .file-name {
  border-left: 1px solid #f4f4f6;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-right: 1px solid #f4f4f6;
  height: 50px;

  color: #7979799a;
}
.action-file {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.action-file span {
  color: #797979;
  font-size: 12px;
  text-align: right;
  cursor: pointer;
}
.action-file svg {
  cursor: pointer;
}
.action-file p {
  color: #7979799a;
  font-size: 11px;
  text-align: right;
  cursor: pointer;
}
.tipology-img {
  background: #ebebec;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tipology-img img {
  max-width: 250px;
  max-height: 180px;
  display: block;
  margin: 0 auto;
  margin-bottom: 7px;
}
.tipology-img span {
  font-size: 11px;
  color: #797979;
  font-weight: 600;
  text-align: center;
  display: block;
  text-transform: uppercase;
  line-height: 1.5;
}
.download-all-file {
  margin-left: auto;
  margin-top: 20px;
}
.download-all-file span {
  font-weight: 600;
}
.download-all-file p {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .header-files img {
    max-width: 150px;
  }
  .files-itens {
    grid-template-columns: 1fr;
  }
  .item-file {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }
  .item-file .file-name {
    border: none;
    padding: 0;
  }
  .item-file:first-child {
    border-top-right-radius: 16px;
  }
  .item-file:last-child {
    border-radius: 0;
  }
  .item-file h5 {
    padding: 10px;
    font-size: 13px;
  }
  .tipology-img {
    border-radius: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .tipology-img img {
    max-width: 200px;
  }
  .item-file span {
    font-size: 12px;
  }
}
</style>