<template>
  <div class="container-fluid px-0 py-2" style="margin-bottom: 100px">
    <div class="topo-itens" v-if="!loading">
      <div>
        <router-link :to="{ name: 'Dashboard' }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-left"
            style="opacity: 0.5"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </router-link>
      </div>
      <div>
        <h3 style="font-weight: 600">Arquivos de Personalização</h3>
      </div>
    </div>

    <div class="col-12">
      <div class="p-dash-cliente-master">
        <div class="col-12 col-md-12 mx-auto">
          <div class="row">
            <div class="col-12 cancela-col-padding" v-if="!loading">
              <div class="container-files">
                <div
                  v-for="item in tipology_files"
                  :key="item.id"
                  class="item-file"
                >
                  <TipologyFileItem :item="item" />
                </div>
              </div>
            </div>

            <div class="col-12 mt-5">
              <BasePagination
                v-if="tipology_files.length > 0 && !loading"
                :totalPages="pagination.totalPages"
                :activePage="pagination.currentPage"
                @to-page="toPage"
                @per-page="perPage"
              />
            </div>
            <div class="col-12 cancela-col-padding" v-if="loading">
              <div class="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TipologyFileService from "@/services/resources/TipologyFileService";
const serviceTipologyFiles = TipologyFileService.build();

import TipologyFileItem from "@/components/Kit/TipologyFile.vue";

export default {
  name: "FileCustomization",
  components: {
    TipologyFileItem,
  },
  data() {
    return {
      loading: false,
      tipology_files: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
        itens: 0,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
  },

  methods: {
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchFiles();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchFiles();
    },
 
  fetchFile(url) {
        fetch(url).then(res => res.blob()).then(file => {
            let tempUrl = URL.createObjectURL(file);
            const aTag = document.createElement("a");
            aTag.href = tempUrl;
            aTag.download = url.replace(/^.*[\\\/]/, '');
            document.body.appendChild(aTag);
            aTag.click();
            downloadBtn.innerText = "Download File";
            URL.revokeObjectURL(tempUrl);
            aTag.remove();
        }).catch(() => {
            alert("Failed to download file!");
            downloadBtn.innerText = "Download File";
        });
    },
      baixarArquivo(path) {
      const linkDoArquivoDaAPI = path;

      const elementoLink = document.createElement("a");
      elementoLink.href = linkDoArquivoDaAPI;
      elementoLink.download = "nome_do_arquivo"; // Defina o nome do arquivo que deseja usar

      elementoLink.style.display = "none";
      document.body.appendChild(elementoLink);

      elementoLink.click();

      document.body.removeChild(elementoLink);
    },
    fetchFiles() {
      this.loading = true;
      let data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        url: `/${this.user.id}`,
      };
      serviceTipologyFiles
        .search(data)
        .then((resp) => {
          this.tipology_files = resp.data;

          this.pagination.totalPages = resp.last_page;
          this.pagination.itens = resp.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 300);
        });
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    this.$nextTick(() => {
      this.fetchFiles();
    });

    const fileInput = document.querySelector("input"),
    downloadBtn = document.querySelector("button");
    downloadBtn.addEventListener("click", e => {
        e.preventDefault();
        downloadBtn.innerText = "Baixando arquivo...";
        fetchFile(fileInput.value);
    });
  },
};
</script>


<style scoped>
.container-fluid {
  min-height: 80vh;
}
.topo-itens {
  background: rgba(244, 244, 246, 0.5);
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #f4f4f6;
}
.topo-itens a,
.topo-itens h3 {
  margin: 0;
  color: #797979;
}
.loading {
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-files + .container-files {
  margin-top: 40px;
}
.item-file + .item-file {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .cancela-col-padding {
    padding: 0 !important;
  }

  .topo-itens {
    padding: 20px;
    gap: 15px;
  }
  .topo-itens h3 {
    font-size: 14px;
  }
}
</style>